/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-300.55536c8e.woff2) format('woff2'), 
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-300.a1471d1d.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-regular.5d4aeb4e.woff2) format('woff2'), 
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-regular.bafb105b.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-500.28546717.woff2) format('woff2'), 
       url(https://pdv.skywork.com.br/static/media/roboto-v18-latin-500.de8b7431.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: RGBA(255,255,255,0);
}
::-webkit-scrollbar-thumb {
  background: RGBA(0,0,0,.2);
  border-radius: 1px;
}
::-webkit-scrollbar-thumb:hover {
  background: RGBA(0,0,0,.3);
}

::-webkit-scrollbar-thumb:vertical {
  border-width: 8 0 8 0;
}

h1,h2,h3,h4{
  font-family: 'Roboto';
  font-weight: 300;
}

body{font-family: 'Roboto'}
